import styled from "styled-components";

const Container = styled.div`
  & div.selected-node > div {
    border: 1px solid #f27c61;
    border-bottom: 6px solid #f27c61;
    border-radius: 0 0 6px 6px;
  }

  & .rstcustom__node {
    min-height: 62px !important;
    height: auto !important;
    position: relative !important;
    top: 0 !important;
  }

  & .rstcustom__rowLabel{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .rstcustom__rowContents{
    min-height: 62px !important;
  }

  & .rstcustom__nodeContent > div {
    height: auto !important;
  }

  & .rstcustom__collapseButton{
    padding-right: 15px
  }

  & .rst__tree{
    height: 72vh !important;
    overflow: auto;
  }

  & .rstcustom__nodeContent {
    position: relative !important;
  }

  & .rstcustom__rowTitle > p {
    margin: 0;
  }
`;

const CenteredButton = styled.div`
  margin: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { Container, CenteredButton };
