import React from "react";
import { Tab } from "./Tab";

export const Tabs = (list, selected, onUpdateTitle) =>
  list.map((el, index) => {

    return (
      <Tab
        key={index}
        item={el}
        selected={selected}
        onUpdateTitle={onUpdateTitle}
      />
    );
  });
