import { IconButton } from "@segfy/segfy-react-components";
import React, { useContext } from "react";
import FieldOptionContext from "../../../../../../contexts/field-option-context";
import { OptionItem, OptionItemButtons } from "./style";
import QuestionnaireContext from "../../../../../../contexts/questionnaire-context";

const Option = ({ item, fieldId, deleteItem }) => {
  const { questionnaire } = useContext(QuestionnaireContext);
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);


  const isSelectedAndEditing = (fieldOption, item) => {
    return (
      fieldOption?.option?.payload?.id === item?.id &&
      fieldOption?.option?.isEditing
    );
  };

  const isNotSelected = (fieldOption, item) => {
    return (
      fieldOption?.option === null ||
      fieldOption?.option?.payload?.id !== item?.id
    );
  };

  const isSelectedAndNotEditing = (fieldOption, item) => {
    return (
      fieldOption?.option?.payload?.id === item?.id &&
      !fieldOption?.option?.isEditing
    );
  };

  const fieldOptionEdition = [
    {
      check: isSelectedAndEditing,
      component: (
        <IconButton
          onClick={() => {
            setFieldOption({
              type: "select-option",
              payload: fieldOption?.option?.payload,
            });
          }}
          transparent_background
          primary
          icon_size={9.5}
          icon="add"
        />
      ),
    },
    {
      check: isNotSelected,
      component: <></>,
    },
    {
      check: isSelectedAndNotEditing,
      component: (
        <IconButton
          onClick={() => {
            setFieldOption({ type: "edit-option" });
          }}
          transparent_background
          primary
          icon_size={9.5}
          icon="edit"
        />
      ),
    },
  ];

  return (
    <OptionItem
      selected={fieldOption?.option?.payload?.id === item.id}
      onClick={() => {
        if (fieldOption?.option?.payload?.id !== item.id) {
          setFieldOption({
            type: "select-option",
            payload: { ...item, title: item.description },
          });
        }
      }}
    >
      {item.description}
      <OptionItemButtons>
      {fieldOptionEdition.find((f) => f.check(fieldOption, item)).component}
        <IconButton
          onClick={() => {
            const confimed = window.confirm(
              "Tem certeza que deseja remover esse option?"
            );

            if (!confimed) return;

            deleteItem(questionnaire.id, fieldId, item.id);
          }}
          icon_size={9.5}
          primary
          icon="trash"
        />
      </OptionItemButtons>
    </OptionItem>
  );
};

export default Option;
