import React, { useEffect, useState } from "react";
import { checkLegacySubscriptionId } from '../../services/auth/auth-services';
import { Redirect } from 'react-router-dom';
import { redirectToLogin } from '../../helpers/redirectToLogin';
import ReactLoading from 'react-loading';
import { Forbidden } from './forbidden';
import { ContainerColumn } from './styles';

export const Verification = ({ isLogged, setIsLogged }) => {
  const [forbidden, setForbidden] = useState(false);

  useEffect(() => {
    checkLegacySubscriptionId()
      .then(({ data }) => {
        if (data.status === "OK") {
          const legacyId = data.segfy.legacy_subscription_id;

          if (legacyId.toString() !== process.env.REACT_APP_SEGFY_ID) {
            return setForbidden(true);
          } else {
            return setIsLogged(true);
          }
        }
      })
      .catch((error) => {
        if (!error.response) return;
        if (error.response.status === 401) {
          redirectToLogin();
        }
      });
  }, [setIsLogged]);

  if (isLogged) {
    return <Redirect to={`/`} />;
  }

  if (forbidden) {
    return <Forbidden />;
  }

  return (
    <ContainerColumn>
      <ReactLoading
        type={'bubbles'}
        color={'var(--segfy-color-red)'}
        height={100}
        width={150}
      />
      <h1>Autenticando</h1>
    </ContainerColumn>
  );
};
