import React, { useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, CenteredButton, ErrosContainer } from "./style";
import {
  Panel,
  MarginTop,
  ItemSpan1,
  ItemSpan2,
  SegfyInputWrapper,
  Button,
  Heading2,
  MarginLeft,
} from "@segfy/segfy-react-components";
import { validationSchema } from "./validator";
import ErrorWrapper from "../../../../ErrorWrapper";
import { convertToJsonApi } from "../../../../../converters/questionnaire/field/field-jsonapi-converter";
import {
  showField,
  updateField,
} from "../../../../../services/questionnaire/field";
import { convertToViewModel } from "../../../../../converters/questionnaire/field/jsonapi-field-converter";
import QuestionnaireContext from "../../../../../contexts/questionnaire-context";
import FieldOptionContext from "../../../../../contexts/field-option-context";
import serverErrorHandle from "../../../../../handlers/server-error-handler";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/pt";

const FieldForm = () => {
  const { questionnaire } = useContext(QuestionnaireContext);
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);

  const {
    register,
    reset,
    control,
    errors,
    handleSubmit,
    setError,
    clearErrors,
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
  });

  useEffect(() => {
    if (questionnaire && fieldOption) {
      showField(questionnaire.id, fieldOption[fieldOption.type].payload.id)
        .then((resp) => {
          console.log(resp);

          const vm = convertToViewModel(resp.data);

          reset(vm);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
        });
    }
  }, [questionnaire, fieldOption, reset]);

  function onSubmit(data) {
    const jsonApi = convertToJsonApi(data);
    updateField(
      questionnaire.id,
      fieldOption[fieldOption.type].payload.id,
      jsonApi
    )
      .then((resp) => {
        setFieldOption({
          type: "select-field",
          payload: {
            id: fieldOption[fieldOption.type].payload.id,
            title: data.name,
          },
        });
      })
      .catch(function (error) {
        console.log(error.response.data.errors);
        serverErrorHandle(error.response.data.errors, setError);
      });
  }

  return (
    <div className="field-form">
      <MarginLeft margin="1.5em">
        <Heading2>{fieldOption[fieldOption.type].payload.title}</Heading2>
      </MarginLeft>

      <MarginTop>
        <Panel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ErrosContainer>
              <ErrorWrapper name={`general-errors`} errors={errors} />
            </ErrosContainer>

            <Container>
              <ItemSpan1>
                <ErrorWrapper name={`name`} errors={errors}>
                  <SegfyInputWrapper label={"Nome*"}>
                    <input
                      type="text"
                      name={`name`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`description`} errors={errors}>
                  <SegfyInputWrapper label={"Descrição*"}>
                    <input
                      type="text"
                      name={`description`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan2>
                <ErrorWrapper name={`params`} errors={errors}>
                  <SegfyInputWrapper label={"Params"}>
                    <Controller
                      name={`params`}
                      control={control}
                      defaultValue={{}}
                      render={(props) => (
                        <JSONInput
                          placeholder={props.value}
                          onChange={(data) => {
                            if (data.error) {
                              setError(`params`, {
                                type: "manual",
                                message: data.error.reason,
                              });
                            } else {
                              clearErrors(`params`);
                              props.onChange(data.jsObject);
                            }
                          }}
                          confirmGood={(result) => console.log(result)}
                          id="params"
                          locale={locale}
                          height="250px"
                          width="100%"
                        />
                      )}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan2>

              <ItemSpan2>
                <ErrorWrapper name={`validations`} errors={errors}>
                  <SegfyInputWrapper label={"Validations"}>
                    <Controller
                      name={`validations`}
                      control={control}
                      defaultValue={{}}
                      render={(props) => (
                        <JSONInput
                          placeholder={props.value}
                          onChange={(data) => {
                            if (data.error) {
                              setError(`validations`, {
                                type: "manual",
                                message: data.error.reason,
                              });
                            } else {
                              clearErrors(`validations`);
                              console.log(formState);
                              props.onChange(data.jsObject);
                            }
                          }}
                          confirmGood={(result) => console.log(result)}
                          id="validations"
                          locale={locale}
                          height="250px"
                          width="100%"
                        />
                      )}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan2>
            </Container>
            <MarginTop>
              <ItemSpan2 align_items="center">
                <CenteredButton>
                  <Button
                    disabled={Object.keys(control.formState.errors).length > 0}
                    type="submit"
                    primary
                    small
                  >
                    Salvar
                  </Button>
                </CenteredButton>
              </ItemSpan2>
            </MarginTop>
          </form>
        </Panel>
      </MarginTop>
    </div>
  );
};

export default FieldForm;
