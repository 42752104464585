import React, { useState, useContext, useEffect } from "react";
import QuestionnaireContext from "../../../contexts/questionnaire-context";
import { listQuestionnaires } from "../../../services/questionnaire";
import {
  Flex,
  Margin,
  customStylesDefault,
  segfyTheme,
} from "@segfy/segfy-react-components";
import { useHistory } from "react-router-dom";
import { convertToViewModel } from "../../../converters/questionnaire/jsonapi-questionnaire-list-converter";
import QuestionnaireNameEditor from "./QuestionnaireNameEditor";
import CustomMenuSelectNewQuestionnaire from "./CustomMenuSelectNewQuestionnaire";
import FieldOptionContext from "../../../contexts/field-option-context";
import Select from "react-select";

const QuestionnaireHeader = () => {
  const { questionnaire, setQuestionnaire } = useContext(QuestionnaireContext);
  const { setFieldOption } = useContext(FieldOptionContext);

  const [questionnairesOptions, setQuestionnairesOptions] = useState([]);

  let history = useHistory();

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);

  const [tick, setTick] = useState(0);

  const getQuestionnaires = () => {
    listQuestionnaires().then((resp) => {
      const vm = convertToViewModel(resp.data);
      setQuestionnairesOptions(vm.questionnaires);
    });
  };

  useEffect(() => {
    getQuestionnaires();
  }, []);

  useEffect(() => {
    if (questionnaire) {
      setSelectedQuestionnaire(questionnaire);
      setTick(tick => tick + 1);
    }
  }, [questionnaire]);

  useEffect(() => {
    if (!questionnaire) {
      setSelectedQuestionnaire(null);
      setTick(tick => tick + 1);
    }
  }, [questionnaire]);

  return (
    <div className="questionnaire-header">
      <Flex space_between vertical_centered>
        <Margin>
          <QuestionnaireNameEditor />
        </Margin>
        <Margin>
          <Select
            key={tick}
            loadingMessage={() => "Carregando..."}
            noOptionsMessage={() => "Nenhuma opção encontrada"}
            theme={segfyTheme}
            classNamePrefix="segfy-react-select"
            styles={customStylesDefault}
            value={selectedQuestionnaire}
            defaultOptions
            placeholder={"Busque o questionário"}
            getOptionLabel={(e) => e.description}
            getOptionValue={(e) => e.id}
            options={questionnairesOptions}
            components={{ MenuList: CustomMenuSelectNewQuestionnaire }}
            onChange={(e) => {
              setSelectedQuestionnaire(e);
              setQuestionnaire(e);
              setFieldOption({ type: "reset"});
              history.push(`/${e.id}`);
            }}
          />
        </Margin>
      </Flex>
    </div>
  );
};

export default QuestionnaireHeader;
