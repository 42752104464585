const isAttributeError = (err) => Object.keys(err.source).length > 0;
const isGeneralError = (err) => Object.keys(err.source).length === 0;

const errorKindAction = [
  {
    check: isAttributeError,
    action: (err) => err.source.pointer.replace("/data/attributes/", ""),
  },
  {
    check: isGeneralError,
    action: (err) => "general-errors",
    default: true,
  },
];

const serverErrorHandle = (errors, setError) => {
  let pathsErros = {};

  errors.forEach((err) => {
    const errorAction = errorKindAction.some((c) => c.check(err))
      ? errorKindAction.find((c) => c.check(err))
      : errorKindAction.find((c) => c.default);

    const path = errorAction.action(err);
    const errorDetail = err.detail;

    if (!pathsErros[path]) {
      pathsErros[path] = { errors: [] };
    }

    pathsErros[path].errors.push(errorDetail);
  });

  Object.keys(pathsErros).forEach(function (item) {
    const path = item;
    const message = pathsErros[item].errors.join("| ");

    setError(path, {
      type: "server",
      message,
      shouldFocus: true,
    });
  });
};

export default serverErrorHandle;
