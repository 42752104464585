import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { GlobalStyle } from "./styles/globalStyles";
import AutomationTabs from "./components/AutomationTabs";

function App() {
  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route path="/:id?" component={AutomationTabs} />
        <Redirect from="*" to="/" />
      </Switch>
    </>
  );
}

export default App;
