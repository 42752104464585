import { map } from "guinho-automapper-js/index";
import createMapping  from "../../mappers/questionnaire/list/jsonapi-questionnaire-list-map";

const convertToViewModel = (data) => {

  const viewModel = map(data, {}, createMapping(data.data.length));

  return viewModel;
};

export { convertToViewModel };