import React, { useState, useContext } from "react";
import QuestionnaireContext from "../../../../contexts/questionnaire-context";
import EditableElement from "../../../EditableElement";
import { IconButton, Flex, Heading1 } from "@segfy/segfy-react-components";
import { Container } from "./style";
import { convertToJsonApi } from "../../../../converters/questionnaire/questionnaire-name-jsonapi-converter";
import { updateQuestionnaire } from "../../../../services/questionnaire";

const QuestionnaireNameEditor = () => {
  const { questionnaire, setQuestionnaire } = useContext(QuestionnaireContext);
  const [isEditing, setIsEditing] = useState(false);

  const [newQuestionnaireName, setNewQuestionnaireName] = useState("");

  const nameChanged = (newName) => {
    setNewQuestionnaireName(newName);
  };

  const saveNewQuestionnaireName = (newName) => {
    const newQuestionnaireNameVm = {
      description: newName,
    };

    const jsonApiNewQuestionnaireName = convertToJsonApi(
      newQuestionnaireNameVm
    );

    updateQuestionnaire(questionnaire.id, jsonApiNewQuestionnaireName)
      .then((resp) => {
        const updatedQuestionnaire = { ...questionnaire, description: newName };
        setQuestionnaire(updatedQuestionnaire);
      })
      .catch(function (error) {
        console.error(error);
      });

    setIsEditing(false);
  };

  return (
    <>
      {questionnaire ? (
        <>
          <Flex space_between>
            <Container>
              <EditableElement editable={isEditing} onChange={nameChanged}>
                <Heading1>{questionnaire?.description}</Heading1>
              </EditableElement>
            </Container>

            {isEditing ? (
              <IconButton
                onClick={() => saveNewQuestionnaireName(newQuestionnaireName)}
                transparent_background
                primary
                icon="add"
              />
            ) : (
              <IconButton
                onClick={() => setIsEditing(true)}
                transparent_background
                primary
                icon="edit"
              />
            )}
          </Flex>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default QuestionnaireNameEditor;
