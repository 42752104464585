import { IconButton } from "@segfy/segfy-react-components";
import React, { useState } from "react";
import EditableElement from "../EditableElement";
import { Container } from "./style";

const EditableTitle = ({ title, onConfirm }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [newTitle, setNewTitle] = useState("");

  const titleChanged = (newName) => {
    setNewTitle(newName);
  };

  const endEditing = (newName) => {
    setIsEditing(false);
    onConfirm(newName);
  };

  return (
    <Container className="editable-title">
      <EditableElement editable={isEditing} onChange={titleChanged}>
        <p className={isEditing ? "editing" : "not-editing"}>
          {title}
        </p>
      </EditableElement>

      {isEditing ? (
        <IconButton
          onClick={() => endEditing(newTitle)}
          transparent_background
          primary
          icon_size={11}
          icon="add"
        />
      ) : (
        <IconButton
          onClick={() => setIsEditing(true)}
          transparent_background
          icon_size={11}
          primary
          icon="edit"
        />
      )}
    </Container>
  );
};

export default EditableTitle;
