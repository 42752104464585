import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, CenteredButton, ErrosContainer } from "./style";
import {
  Panel,
  MarginTop,
  ItemSpan1,
  ItemSpan2,
  SegfyInputWrapper,
  Button,
  Heading2,
  MarginLeft,
} from "@segfy/segfy-react-components";
import { validationSchema } from "./validator";
import ErrorWrapper from "../../../../../../ErrorWrapper";

import {
  showOption,
  updateOption,
} from "../../../../../../../services/questionnaire/field/option";
import { convertToJsonApi } from "../../../../../../../converters/questionnaire/field/option/option-jsonapi-converter";
import { convertToViewModel } from "../../../../../../../converters/questionnaire/field/option/jsonapi-field-converter";
import QuestionnaireContext from "../../../../../../../contexts/questionnaire-context";
import FieldOptionContext from "../../../../../../../contexts/field-option-context";
import serverErrorHandle from "../../../../../../../handlers/server-error-handler";

const OptionForm = () => {
  const { questionnaire } = useContext(QuestionnaireContext);
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);

  const {
    register,
    reset,
    control,
    errors,
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
  });

  useEffect(() => {
    if (questionnaire && fieldOption) {
      showOption(
        questionnaire.id,
        fieldOption.field.payload.id,
        fieldOption.option.payload.id
      )
        .then((resp) => {
          const vm = convertToViewModel(resp.data);

          reset(vm);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
        });
    }
  }, [questionnaire, fieldOption, reset]);

  function onSubmit(data) {
    const jsonApi = convertToJsonApi(data);
    updateOption(
      questionnaire.id,
      fieldOption.field.payload.id,
      fieldOption.option.payload.id,
      jsonApi
    )
      .then((resp) => {
        setFieldOption({
          type: "select-option",
          payload: {
            id: fieldOption.option.payload.id,
            title: data.description,
          },
        });
      })
      .catch(function (error) {
        console.log(error.response.data.errors);
        serverErrorHandle(error.response.data.errors, setError);
      });
  }

  return (
    <div className="option-form">
      <MarginLeft margin="1.5em">
        <Heading2>{fieldOption[fieldOption.type].payload.title}</Heading2>
      </MarginLeft>

      <MarginTop>
        <Panel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ErrosContainer>
              <ErrorWrapper name={`general-errors`} errors={errors} />
            </ErrosContainer>

            <Container>
              <ItemSpan1>
                <ErrorWrapper name={`description`} errors={errors}>
                  <SegfyInputWrapper label={"Descrição*"}>
                    <input
                      type="text"
                      name={`description`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`value`} errors={errors}>
                  <SegfyInputWrapper label={"Valor*"}>
                    <input
                      type="text"
                      name={`value`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>
            </Container>
            <MarginTop>
              <ItemSpan2 align_items="center">
                <CenteredButton>
                  <Button
                    disabled={Object.keys(control.formState.errors).length > 0}
                    type="submit"
                    primary
                    small
                  >
                    Salvar
                  </Button>
                </CenteredButton>
              </ItemSpan2>
            </MarginTop>
          </form>
        </Panel>
      </MarginTop>
    </div>
  );
};

export default OptionForm;
