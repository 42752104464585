import React, { useContext } from "react";
import SortableTree, {
  changeNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-minimal";
import Field from "../Field";
import { IconButton } from "@segfy/segfy-react-components";
import { Container, CenteredButton } from "./styles";
import "react-sortable-tree/style.css";
import FieldOptionContext from "../../../../contexts/field-option-context";
import Options from "../Field/Options";

const SortableFieldsTree = ({
  treeData,
  setTreeData,
  addItem,
  removeItem,
  updateItemParent,
}) => {
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);

  const nodeClicked = (event, rowInfo) => {
    if (
      event.target.className.includes("collapseButton") ||
      event.target.className.includes("expandButton")
    ) {
      // ignore the event
    } else if (fieldOption?.field?.payload?.id !== rowInfo.node.id) {
      setFieldOption({ type: "select-field", payload: rowInfo.node });
    }
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const afterTitleUpdated = (newTitle, item, path) => {
    setTreeData(
      changeNodeAtPath({
        treeData,
        path,
        getNodeKey,
        newNode: { ...item, title: newTitle },
      })
    );
  };

  return (
    <Container style={{ minHeight: 450 }}>
      <SortableTree
        isVirtualized={false}
        treeData={treeData}
        onMoveNode={(args) => {
          const { nextParentNode, node } = args;

          updateItemParent(node.id, nextParentNode?.id || null);
        }}
        onChange={(treeData) => setTreeData(treeData)}
        theme={FileExplorerTheme}
        generateNodeProps={(rowInfo) => {
          //const selectedOpt = rowInfo.node.options?.find((o) => o.selected);

          let nodeProps = {
            onClick: (event) => nodeClicked(event, rowInfo),
            subtitle: (
              <>
                {fieldOption?.field?.payload?.id === rowInfo.node.id ? (
                  <Options fieldId={rowInfo.node.id} />
                ) : (
                  <></>
                )}
              </>
            ),
            title: (
              <p>
                <Field
                  item={rowInfo.node}
                  path={rowInfo.path}
                  afterTitleUpdated={afterTitleUpdated}
                />

              </p>
            ),
            buttons: [
              <IconButton
                onClick={() => {
                  const confimed = window.confirm(
                    "Tem certeza que deseja remover esse campo?"
                  );

                  if (!confimed) return;

                  const path = rowInfo.path;

                  const removeNode = () => {
                    setTreeData(
                      removeNodeAtPath({
                        treeData,
                        path,
                        getNodeKey,
                        ignoreCollapsed: false,
                      })
                    );
                  };

                  removeItem(rowInfo.node.id, removeNode);
                }}
                transparent_background
                primary
                icon="trash"
              />,
            ],
          };

          if (fieldOption?.field?.payload?.id === rowInfo.node.id) {
            nodeProps.className = "selected-node";
          }
          return nodeProps;
        }}
      ></SortableTree>
      <CenteredButton>
        <IconButton title="Adicionar field" primary icon="add" onClick={addItem} />
      </CenteredButton>
    </Container>
  );
};

export default SortableFieldsTree;
