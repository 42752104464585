import { get, post, put } from "../api-service";

const filterCompaniesOptions = (filter) => {
  return get(`/company_options?${filter}`);
};

const listCompaniesOptions = () => {
  return get(`/company_options`);
};

const createCompanyOption = (data) => {
  return post(`/company_options`, data);
};

const updateCompanyOption = (id, data) => {
  return put(`/company_options/${id}`, data);
};

export {
  filterCompaniesOptions,
  listCompaniesOptions,
  createCompanyOption,
  updateCompanyOption,
};
