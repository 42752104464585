import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { OpenSansRegular, GlobalStyles } from "@segfy/segfy-react-components";
import App from "./App";

ReactDOM.render(
  <>
    <OpenSansRegular />
    <GlobalStyles />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
