import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  & ul {
    padding: 0;
    padding-top: 3px;
    width: 100%;
    margin-bottom: 0;
  }
`;

export { Container };
