const createMapping = (lenght) => {
    let mapping = [];
  
    for (let i = 0; i < lenght; i++) {
      mapping = [
        ...mapping,
        ...[
          {
            from: `data[${i}].id`,
            to: `options[${i}].id`,
          },
  
          {
            from: `data[${i}].attributes.description`,
            to: `options[${i}].description`,
          },
  
          {
            from: `data[${i}].attributes.value`,
            to: `options[${i}].value`,
          },
  
        ],
      ];
    }
  
    return mapping;
  };
  
  export default createMapping;