const mapping = [
  {
    from: "id",
    to: "id",
  },
  {
    from: "attributes.company_id",
    to: "company_id",
  },
  {
    from: "attributes.option_id",
    to: "option_id",
  },
  {
    from: "attributes.field_id",
    to: "field_id",
  },
  {
    from: "attributes.value",
    to: "value",
  },
  {
    from: "attributes.description",
    to: "description",
  },
  {
    from: "attributes.code",
    to: "code",
  },
];

export default mapping;
