import React, { useState, useEffect, useContext } from "react";
import CredentialContext from "../../../contexts/credential-context";
import { listTokens } from "../../../services/brokers/tokens";
import { listCredentials } from "../../../services/brokers/tokens/credentials";
import { TableContainer } from "./style";

function TokensCredentials({ brokerId, tick }) {
  const [tokensCredentials, setTokensCredentials] = useState([]);

  const { setCredential } = useContext(CredentialContext);

  useEffect(() => {
    if (brokerId) {
      listTokens(brokerId)
        .then((resp) => {
          const newTokens = resp.data.data;

          console.log(newTokens);

          return newTokens.map((t) => {
            return {
              id: t.id,
              token: t.attributes.token,
              active: t.attributes.active,
            };
          });
        })
        .then((tokens) => {
          console.log(tokens);
          return Promise.all(getAllCredentials(brokerId, tokens));
        })
        .then((tokensCredentials) => {
          console.log(tokensCredentials);
          setTokensCredentials(tokensCredentials);
        })
        .catch((error) => {});
    }
  }, [brokerId, tick]);

  const getAllCredentials = (brokerId, tokens) => {
    return tokens.map(
      (token) =>
        new Promise((resolve) => {
          resolve(
            listCredentials(brokerId, token.id).then((resp) => {
              const credentials = resp.data.data;

              const convertedCredentials = credentials.map((t) => {
                return {
                  id: t.id,
                  user: t.attributes.user,
                  companyId: t.attributes.company_id,
                  tokenId: token.id,
                  brokerId: brokerId,
                };
              });

              return {
                ...token,
                credentials: convertedCredentials,
              };
            })
          );
        })
    );
  };

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            <th>Token</th>
            <th>User</th>
            <th>Ativo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tokensCredentials &&
            tokensCredentials.map((token, index) => (
              <React.Fragment key={`${token.id}-${index}`}>
                <tr>
                  <td>{token.token}</td>
                  <td></td>
                  <td>{token.active}</td>
                  <td>
                    <a
                      href="-"
                      onClick={(e) => {
                        e.preventDefault();
                        setCredential({
                          brokerId,
                          tokenId: token.id,
                          isEditing: false,
                          isCreating: true,
                        });
                      }}
                    >
                      Nova credencial
                    </a>
                  </td>
                </tr>
                {token.credentials.map((cred, index) => (
                  <tr>
                    <td>{token.token}</td>
                    <td>{cred.user}</td>
                    <td></td>
                    <td>
                      <a
                        href="-"
                        onClick={(e) => {
                          e.preventDefault();
                          setCredential({
                            ...cred,
                            isEditing: true,
                            isCreating: false,
                          });
                        }}
                      >
                        Editar
                      </a>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
}

export default TokensCredentials;
