import { Heading2, IconButton } from "@segfy/segfy-react-components";
import React, { useState } from "react";
import { listBrokers } from "../../services/brokers";
import { Centered, Container, TableContainer } from "./style";
import { createToken } from "../../services/brokers/tokens";
import TokensCredentials from "./TokensCredentials";
import TokenGenerator from "uuid-token-generator";

function Brokers() {
  const [brokers, setBrokers] = useState([]);
  const [tick, setTick] = useState(0);

  const [openedTokensCredentials, setOpenedTokensCredentials] = useState(null);

  useState(() => {
    listBrokers()
      .then((resp) => {
        const newBrokers = resp.data.data;

        setBrokers(newBrokers);
      })
      .catch((error) => {});
  }, []);

  const addToken = (brokerId) => {
    const jsonApi = {
      data: {
        type: "tokens",
        attributes: {
          token: new TokenGenerator(TokenGenerator.BASE36).generate(),
          active: true,
        },
      },
    };
    createToken(brokerId, jsonApi)
      .then((resp) => {
        setTick((tick) => tick + 1);
        setOpenedTokensCredentials(brokerId)
      })
      .catch((error) => {});
  };

  return (
    <Container>
      <Heading2>Corretoras</Heading2>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Intranet</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brokers &&
              brokers.map((broker, index) => (
                <React.Fragment key={`${broker.id}-${index}`}>
                  <tr>
                    <td>{broker.id}</td>
                    <td>{broker.attributes.name}</td>
                    <td>{broker.attributes.intranet}</td>

                    <td>
                      <a
                        href="-"
                        onClick={(e) => {
                          e.preventDefault();
                          addToken(broker.id);
                        }}
                      >
                        Adicionar token
                      </a>
                    </td>
                  </tr>
                  {openedTokensCredentials === broker.id ? (
                    <tr>
                      <th colSpan={4}>
                        <TokensCredentials brokerId={broker.id} tick={tick} />
                      </th>
                    </tr>
                  ) : (
                    <></>
                  )}

                  <tr className="open-tokens">
                    <th colSpan={4}>
                      <Centered>
                        {openedTokensCredentials === broker.id ? (
                          <IconButton
                            onClick={() => setOpenedTokensCredentials(null)}
                            transparent_background
                            primary
                            icon_size={10}
                            icon="arrow_right"
                          />
                        ) : (
                          <IconButton
                            onClick={() =>
                              setOpenedTokensCredentials(broker.id)
                            }
                            transparent_background
                            primary
                            icon_size={10}
                            icon="arrow_down"
                          />
                        )}
                      </Centered>
                    </th>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  );
}

export default Brokers;
