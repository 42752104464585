import React, { useContext } from "react";
import { IconButton } from "@segfy/segfy-react-components";
import { Container } from "./style";
import FieldOptionContext from "../../../../contexts/field-option-context";

const Field = ({ item }) => {
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);

  const isSelectedAndEditing = (fieldOption, item) => {
    return (
      fieldOption?.field?.payload?.id === item?.id &&
      fieldOption?.field?.isEditing
    );
  };

  const isNotSelected = (fieldOption, item) => {
    return (
      fieldOption?.field === null ||
      fieldOption?.field?.payload?.id !== item?.id
    );
  };

  const isSelectedAndNotEditing = (fieldOption, item) => {
    return (
      fieldOption?.field?.payload?.id === item?.id &&
      !fieldOption?.field?.isEditing
    );
  };

  const fieldOptionEdition = [
    {
      check: isSelectedAndEditing,
      component: (
        <IconButton
          onClick={() => {
            setFieldOption({
              type: "select-field",
              payload: fieldOption?.field?.payload,
            });
          }}
          transparent_background
          primary
          icon_size={11}
          icon="add"
        />
      ),
    },
    {
      check: isNotSelected,
      component: <></>,
    },
    {
      check: isSelectedAndNotEditing,
      component: (
        <IconButton
          onClick={() => {
            setFieldOption({ type: "edit-field" });
          }}
          transparent_background
          primary
          icon_size={11}
          icon="edit"
        />
      ),
    },
  ];

  return (
    <Container title={item?.title}>
      <strong>{item?.title}</strong>
      {fieldOptionEdition.find((f) => f.check(fieldOption, item)).component}
    </Container>
  );
};

export default Field;
