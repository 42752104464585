import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-gap: 0.5em 1em;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;

const CenteredButton = styled.div`
  display: flex;
  margin-top: .6em;
  justify-content: center;
`;

const ErrosContainer = styled.div`
  display: flex;
  align-items: center;
  margin: .5em 0
`;

export { Container, CenteredButton, ErrosContainer };
