const mapping = [
  {
    from: "type",
    to: "data.type",
    fallback_value: "questionnaires",
  },
  {
    from: "description",
    to: "data.attributes.description",
  },
  {
    from: "kind",
    to: "data.attributes.kind",
  },
  {
    from: "active",
    to: "data.attributes.active",
  },
  {
    from: "version",
    to: "data.attributes.version",
    fallback_value: "1.0",
  },
  {
    from: "trigger",
    to: "data.attributes.trigger",
    fallback_value: "Some::Class",
  },
  {
    from: "business",
    to: "data.attributes.business",
  }
];

export default mapping;
