import React from "react";
import EditableTitle from "../../../EditableTitle";
import { TabItem } from "./style";

export const Tab = ({ item, selected, onUpdateTitle }) => {
  return (
    <TabItem selected={selected}>
      {selected ? (
        <EditableTitle
          title={item?.label}
          onConfirm={(newTitle) => onUpdateTitle(item, newTitle)}
        />
      ) : (
        <p>{item?.label}</p>
      )}
    </TabItem>
  );
};
