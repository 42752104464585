import { map } from "guinho-automapper-js/index";
import mapping  from "../../../../mappers/questionnaire/field/option/new/option-jsonapi-map";

const convertToJsonApi = (data) => {

  const viewModel = map(data, {}, mapping);

  return viewModel;
};

export { convertToJsonApi };