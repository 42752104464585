import React, { useEffect, useState } from "react";
import {
  createCompany,
  listCompanies,
  updateCompany,
} from "../../services/company";
import { convertToViewModel } from "../../converters/company/jsonapi-company-list-converter";
import ScrollableSelector from "../ScrollableSelector";

function CompaniesSelector({ onSelectedItem, fullWidth, defaultSelectedId }) {
  const [companiesScrollableItems, setCompaniesScrollableItems] = useState([]);

  useEffect(() => {
    listAndSetCompaniesScrollableItems();
  }, []);

  const listAndSetCompaniesScrollableItems = () => {
    listCompanies("?per_page=100")
      .then((resp) => {
        const vm = convertToViewModel(resp.data);

        const orderedVm = vm.companies.sort((a, b) => a.id - b.id);

        const newCompaniesScrollableItems = orderedVm.map((item) => {
          return { label: item.name, payload: item };
        });

        setCompaniesScrollableItems(newCompaniesScrollableItems);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const onAddItem = (name) => {
    const newCompany = {
      data: {
        type: "companies",
        attributes: {
          business: "vehicle",
          status: "active",
          name: name,
        },
      },
    };

    createCompany(newCompany)
      .then((resp) => {
        listAndSetCompaniesScrollableItems();
        console.log(resp);
      })
      .catch(function (error) {
        console.log(error.response.data.errors);
      });
  };

  const onUpdateTitle = (item, newTitle) => {
    const id = item.payload.id;

    const jsonapi = {
      data: {
        type: "companies",
        attributes: {
          name: newTitle,
        },
      },
    };

    updateCompany(id, jsonapi)
      .then((resp) => {
        listAndSetCompaniesScrollableItems();
        console.log(resp);
      })
      .catch(function (error) {
        console.log(error.response.data.errors);
      });
  };

  return (
    <>
      <ScrollableSelector
        items={companiesScrollableItems}
        onSelectedItem={onSelectedItem}
        onAddItem={onAddItem}
        onUpdateTitle={onUpdateTitle}
        allowAddItem={false}
        fullWidth={fullWidth}
        defaultSelectedId={defaultSelectedId}
      />
    </>
  );
}

export default CompaniesSelector;
