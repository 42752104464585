import { get, post, put } from "../api-service";

const listQuestionnaires = () => {
  return get(`/questionnaires?page=all`);
};

const showQuestionnaire = (id) => {
  return get(`/questionnaires/${id}`);
};

const createQuestionnaire = (data) => {
  return post(`/questionnaires`, data);
};

const updateQuestionnaire = (id, data) => {
  return put(`/questionnaires/${id}`, data);
};

export {
  listQuestionnaires,
  showQuestionnaire,
  createQuestionnaire,
  updateQuestionnaire,
};
