const mapping = [
  {
    from: "type",
    to: "data.type",
    fallback_value: "questionnaires",
  },
  {
    from: "title",
    to: "data.attributes.description",
  },
  {
    from: "title",
    to: "data.attributes.name",
  },
  {
    from: "required",
    to: "data.attributes.required",
    fallback_value: true,
  },
];

export default mapping;
