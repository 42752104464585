const mapping = [
  {
    from: "data.id",
    to: "id",
  },
  {
    from: "data.attributes.description",
    to: "description",
  },
  {
    from: "data.attributes.name",
    to: "name",
  },
  {
    from: "data.attributes.params",
    to: "params",
  },
  {
    from: "data.attributes.validations",
    to: "validations",
  },
];

export default mapping;
