import styled, { css } from "styled-components";

const OptionItem = styled.li`
white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  list-style: none;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  cursor: pointer;
  font-weight: bold;
  font-size: 9.6pt;
  border-radius: 1px;
  border: 1px solid #faf7f2;
  border-right: 3px solid #f27c61;
  margin-bottom: 3px;
  justify-content: space-between;

  & button {
    background-color: transparent;
  }

  &:hover {
    ${(props) =>
      props.selected === false &&
      css`
        background-color: #faf7f2;
      `};
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: #d8d8d7;
    `};
`;

const OptionItemButtons = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export { OptionItem, OptionItemButtons };
