import { MSG_REQUIRED } from "../../../../helpers/validator-helper";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  description: Yup.string().required(MSG_REQUIRED),
  value: Yup.string().when("description", {
    is: (description) => description === "" || description === null,
    then: Yup.string().required(MSG_REQUIRED),
  }),
  code: Yup.string().notRequired(),
});
