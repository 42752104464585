import React, { useCallback, useContext, useEffect, useState } from "react";
import Option from "./Option";
import {
  listOptions,
  createOption,
  deleteOption,
} from "../../../../../services/questionnaire/field/option";
import QuestionnaireContext from "../../../../../contexts/questionnaire-context";
import { convertToViewModel } from "../../../../../converters/questionnaire/field/option/jsonapi-option-list-converter";
import { convertToJsonApi } from "../../../../../converters/questionnaire/field/option/option-jsonapi-converter";
import { IconButton } from "@segfy/segfy-react-components";
import { uniqueNamesGenerator, adjectives } from "unique-names-generator";
import FieldOptionContext from "../../../../../contexts/field-option-context";
import { Container } from "./style";

const Options = ({ fieldId }) => {
  const [optionsItems, setOptionsItems] = useState([]);

  const { questionnaire } = useContext(QuestionnaireContext);
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);

  const listAllOptions = useCallback((questionnaireId, fieldId) => {
    listOptions(questionnaireId, fieldId)
      .then((resp) => {
        const vm = convertToViewModel(resp.data);

        setOptionsItems(vm.options);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (fieldOption && questionnaire && !fieldOption.option?.isEditing) {
      listAllOptions(questionnaire.id, fieldId);
    }
  }, [fieldOption, questionnaire, listAllOptions, fieldId]);

  const addOption = () => {
    const newFieldName = uniqueNamesGenerator({
      dictionaries: [adjectives],
    });

    const jsonApi = convertToJsonApi({
      description: newFieldName,
      value: newFieldName,
    });

    createOption(questionnaire.id, fieldId, jsonApi)
      .then((resp) => {
        listAllOptions(questionnaire.id, fieldId);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const deleteItem = (questionnaire_id, field_id, id) => {
    deleteOption(questionnaire_id, field_id, id)
      .then((resp) => {
        listAllOptions(questionnaire.id, fieldId);
        setFieldOption({
          type: "select-field",
          payload: fieldOption?.field?.payload,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Container>
      {optionsItems ? (
        <ul>
          {optionsItems.map((item) => (
            <Option
              key={item.id}
              item={item}
              fieldId={fieldId}
              deleteItem={deleteItem}
            />
          ))}
        </ul>
      ) : (
        <></>
      )}
      <IconButton
        title="Adicionar option"
        icon_size={9}
        primary
        icon="add"
        onClick={addOption}
      />
    </Container>
  );
};

export default Options;
