import React, { useContext } from "react";
import { components } from "react-select";
import { Button } from "@segfy/segfy-react-components";
import { useHistory } from "react-router-dom";
import QuestionnaireContext from "../../../../contexts/questionnaire-context";
import FieldOptionContext from "../../../../contexts/field-option-context";

const menuStyle = {
  marginTop: '.5em',
  marginBottom: '1em',
  display: 'flex',
  justifyContent: 'center'
};

const CustomMenuSelectNewQuestionnaire = (props) => {
  const { setQuestionnaire } = useContext(QuestionnaireContext);
  const { setFieldOption } = useContext(FieldOptionContext);

  let history = useHistory();

  const newQuestionary = () => {
    setQuestionnaire(null);
    setFieldOption({ type: "reset"});
    history.push(`/`);
  };

  return (
    <>
      <components.MenuList {...props}>
        

        <div style={menuStyle}>
          <Button onClick={newQuestionary} small>
             Novo questionário
          </Button>
        </div>

        {props.children}
      </components.MenuList>
    </>
  );
};

export default CustomMenuSelectNewQuestionnaire;
