const createMapping = (lenght) => {
    let mapping = [];
  
    for (let i = 0; i < lenght; i++) {
      mapping.push({
        from: `data[${i}].id`,
        to: `companies[${i}].id`,
      });
  
      mapping.push({
        from: `data[${i}].attributes.name`,
        to: `companies[${i}].name`,
      });
  
      mapping.push({
        from: `data[${i}].attributes.business`,
        to: `companies[${i}].business`,
      });
  
      mapping.push({
        from: `data[${i}].attributes.status`,
        to: `companies[${i}].status`,
      });
  
    }
  
    return mapping;
  };
  
  export default createMapping;
  