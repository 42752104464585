import styled from "styled-components";

export const ContainerColumn = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ForbiddenContainer = styled(ContainerColumn)`
  h3 {
    width: 25ch;
    text-align: center;
    margin: 32px 0 44px 0;
  }
`;
