import {
  Button,
  Flex,
  Heading2,
  IconButton,
  ItemSpan1,
  ItemSpan2,
  MarginLeft,
  MarginTop,
  Panel,
  SegfyInputWrapper,
} from "@segfy/segfy-react-components";
import React, { useContext, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { validationSchema } from "./validator";
import CompaniesSelector from "../../CompaniesSelector";
import ErrorWrapper from "../../ErrorWrapper";
import { Container, CenteredButton, ErrosContainer } from "./style";
import { yupResolver } from "@hookform/resolvers/yup";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/pt";
import CredentialContext from "../../../contexts/credential-context";
import {
  listCredentials,
  createCredential,
  updateCredential,
} from "../../../services/brokers/tokens/credentials";

function CredentialForm() {
  const { credential, setCredential } = useContext(CredentialContext);

  const [ selectedCompanyId, setSelectedCompanyId ] = useState(null);

  const {
    register,
    reset,
    control,
    errors,
    handleSubmit,
    setError,
    clearErrors,
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
  });

  useEffect(() => {
    console.log(selectedCompanyId);
    if (selectedCompanyId) {
      const newCompanyId = selectedCompanyId;
      const brokerId = credential.brokerId;
      const tokenId = credential.tokenId;

      listCredentials(brokerId, tokenId, `?company_id=${newCompanyId}`)
        .then((resp) => {
          const credentials = resp.data.data;

          if (!credentials || credentials.length === 0) {
            setCredential({
              companyId: newCompanyId,
              brokerId,
              tokenId,
              isCreating: true,
            });
            return;
          }

          const convertedCredentials = credentials.map((t) => {
            return {
              id: t.id,
              user: t.attributes.user,
              companyId: t.attributes.company_id,
              tokenId: tokenId,
              brokerId: brokerId,
            };
          });

          const cred = convertedCredentials[0];

          setCredential({ ...cred, isEditing: true });
        })
        .catch((error) => {});
    }
  }, [selectedCompanyId, credential, setCredential]);

  useEffect(() => {
    if (credential && credential.isEditing) {
      reset(credential);
    }
  }, [credential, reset]);

  function onSubmit(data) {
    const brokerId = credential.brokerId;
    const tokenId = credential.tokenId;
    const companyId = selectedCompanyId;

    const jsonApiModel = createJsonApiModel(data, companyId);

    if (credential.isCreating) {
      createCredential(brokerId, tokenId, jsonApiModel)
        .then((resp) => {
          const cred = resp.data.data.attributes;

          setCredential({ ...cred, isEditing: true });
        })
        .catch((error) => {});
    } else {
      const id = credential.id;
      updateCredential(brokerId, tokenId, id, jsonApiModel)
        .then((resp) => {
          const cred = resp.data.data.attributes;

          setCredential({ ...cred, isEditing: true });
        })
        .catch((error) => {});
    }
  }

  const createJsonApiModel = (data, companyId) => {
    return {
      data: {
        type: "credentials",
        attributes: {
          ...data,
          company_id: companyId,
          status: "invalidity",
        },
      },
    };
  };

  const onSelectedCompany = (company) => {
    setSelectedCompanyId(company.id);
  };

  return (
    <>
      <CompaniesSelector
        fullWidth={true}
        onSelectedItem={onSelectedCompany}
        defaultSelectedId={credential?.company_id}
      />

      <div className="credential-form">
        <MarginLeft margin="1.5em">
          <Flex vertical_centered>
            <IconButton
              onClick={() =>
                setCredential({ isCreating: false, isEditing: false })
              }
              transparent_background
              primary
              icon_size={15}
              icon="up"
            />
            <Heading2>{credential?.user}</Heading2>
          </Flex>
        </MarginLeft>

        <MarginTop>
          <Panel>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ErrosContainer>
                <ErrorWrapper name={`general-errors`} errors={errors} />
              </ErrosContainer>

              <Container>
                <ItemSpan1>
                  <ErrorWrapper name={`user`} errors={errors}>
                    <SegfyInputWrapper label={"Usuário*"}>
                      <input
                        type="text"
                        name={`user`}
                        ref={register}
                        defaultValue={""}
                      />
                    </SegfyInputWrapper>
                  </ErrorWrapper>
                </ItemSpan1>

                <ItemSpan1>
                  <ErrorWrapper name={`password`} errors={errors}>
                    <SegfyInputWrapper label={"Senha*"}>
                      <input
                        type="password"
                        name={`password`}
                        ref={register}
                        defaultValue={""}
                      />
                    </SegfyInputWrapper>
                  </ErrorWrapper>
                </ItemSpan1>

                <ItemSpan2>
                  <ErrorWrapper name={`params`} errors={errors}>
                    <SegfyInputWrapper label={"Params"}>
                      <Controller
                        name={`params`}
                        control={control}
                        defaultValue={{}}
                        render={(props) => (
                          <JSONInput
                            placeholder={props.value}
                            onChange={(data) => {
                              if (data.error) {
                                setError(`params`, {
                                  type: "manual",
                                  message: data.error.reason,
                                });
                              } else {
                                clearErrors(`params`);
                                props.onChange(data.jsObject);
                              }
                            }}
                            confirmGood={(result) => console.log(result)}
                            id="params"
                            locale={locale}
                            height="250px"
                            width="100%"
                          />
                        )}
                      />
                    </SegfyInputWrapper>
                  </ErrorWrapper>
                </ItemSpan2>

                <ItemSpan2>
                  <ErrorWrapper name={`session`} errors={errors}>
                    <SegfyInputWrapper label={"Session"}>
                      <Controller
                        name={`session`}
                        control={control}
                        defaultValue={{}}
                        render={(props) => (
                          <JSONInput
                            placeholder={props.value}
                            onChange={(data) => {
                              if (data.error) {
                                setError(`validations`, {
                                  type: "manual",
                                  message: data.error.reason,
                                });
                              } else {
                                clearErrors(`validations`);
                                console.log(formState);
                                props.onChange(data.jsObject);
                              }
                            }}
                            confirmGood={(result) => console.log(result)}
                            id="validations"
                            locale={locale}
                            height="250px"
                            width="100%"
                          />
                        )}
                      />
                    </SegfyInputWrapper>
                  </ErrorWrapper>
                </ItemSpan2>
              </Container>
              <MarginTop>
                <ItemSpan2 align_items="center">
                  <CenteredButton>
                    <Button
                      disabled={
                        Object.keys(control.formState.errors).length > 0
                      }
                      type="submit"
                      primary
                      small
                    >
                      Salvar
                    </Button>
                  </CenteredButton>
                </ItemSpan2>
              </MarginTop>
            </form>
          </Panel>
        </MarginTop>
      </div>
    </>
  );
}
export default CredentialForm;
