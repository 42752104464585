import React, { useState } from "react";
import CompaniesSelector from "../../CompaniesSelector";
import CompanyOptionForm from "./CompanyOptionForm";
import { Container } from "./style";

const CompaniesOptions = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);


  const onSelectedItem = (company) => {
    setSelectedCompany(company);
  };

  return (
    <Container>
      <CompaniesSelector
        onSelectedItem={onSelectedItem}
      />

      <CompanyOptionForm company={selectedCompany} />
    </Container>
  );
};

export default CompaniesOptions;
