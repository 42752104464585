import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`  
 :root {
    --segfy-color-black: #807973;
    --segfy-color-darkgrey: #b1aba6;
    --segfy-color-grey: #dedede;
    --segfy-color-lightgrey: #f1f3f4;
    --segfy-color-accent: #faf7f2;
    --segfy-color-red: #d25032;
    --segfy-color-lightred: #f2937e;
    --segfy-color-orange: #f4ad4b;
    --segfy-color-lime: #e2ed8d;
    --segfy-color-green: #69c782;
    --segfy-color-lightgreen: #c8f2d3;
    --segfy-color-cyan: #8deddb;
    --segfy-color-lightblue: #b1dff0;
    --segfy-color-white: #ffffff;
    --segfy-color-green-lightest: #69c7826e;
  }
  .react-tabs {
    
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0;

    @media(max-width: 767px)
    {
      display: flex;
      overflow: auto;
    }
  }
  
  .react-tabs__tab {
    border-radius: 1em 1em 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 0.4em 1.5em;
    cursor: pointer;
    margin: 0.5em 0.3em;
    color: var(--segfy-color-black);

    @media(max-width: 767px)
    {
      padding: 10px;
      font-size: smaller;
    }
  }
  
  .react-tabs__tab--selected {
    background: var(--segfy-color-accent);
    color: var(--segfy-color-red);
  }
  
  .react-tabs__tab--selected:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--segfy-color-red);
    height: 4px;
    border-radius: 10px;
  }
  
  .react-tabs__tab--disabled {
    color: #b1aba6;
    cursor: not-allowed;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px var(--segfy-color-red);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: var(--segfy-color-accent);
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
`;

export const CodeFormat = styled.code`
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
`;
