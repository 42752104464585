import styled, { css } from "styled-components";

const TabItem = styled.div`
  padding: 0 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9.3pt;
  width: 90px;
  height: 40px;
  cursor: pointer;
  background-color: #faf7f2;
  color: #807973;

  border: 1px solid #e8e6e1;

  & > .editable-title {
    width: 90px;
  }

  & > .editable-title > .not-editing,
  & > p {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-child {
    border-left: 1px solid #e8e6e1;
  }

  &:not(:first-child) {
    border-left: none;
  }

  ${(props) =>
    props.selected &&
    css`
      color: black;
      box-shadow: -2px -2px 5px #ddd;
      background-color: white;
    `};
`;

export { TabItem };
