import { get, post } from "../../api-service";

const listTokens = (brokerId) => {
  return get(`/brokers/${brokerId}/tokens`);
};

const createToken = (brokerId, data) => {
  return post(`/brokers/${brokerId}/tokens`, data);
};

export { listTokens, createToken };
