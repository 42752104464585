import { MSG_REQUIRED } from "../../../helpers/validator-helper";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  description: Yup.string().required(MSG_REQUIRED),
  kind: Yup.string().required(MSG_REQUIRED),
  active: Yup.boolean().notRequired(),
  version: Yup.string().required(MSG_REQUIRED),
  trigger: Yup.string().required(MSG_REQUIRED),
  business: Yup.string().required(MSG_REQUIRED),
});
