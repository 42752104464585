const mapping = [
    {
      from: "type",
      to: "data.type",
      fallback_value: "questionnaires",
    },
    {
      from: "description",
      to: "data.attributes.description",
    }
  ];
  
  export default mapping;