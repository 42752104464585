import { get, post, put } from "../../../api-service";

const listCredentials = (brokerId, tokenId, searchParams = "") => {
  return get(
    `/brokers/${brokerId}/tokens/${tokenId}/credentials${searchParams}`
  );
};

const createCredential = (brokerId, tokenId, data) => {
  return post(`/brokers/${brokerId}/tokens/${tokenId}/credentials`, data);
};

const updateCredential = (brokerId, tokenId, id, data) => {
  return put(`/brokers/${brokerId}/tokens/${tokenId}/credentials/${id}`, data);
};

export { listCredentials, createCredential, updateCredential };
