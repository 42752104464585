import React, { useState, useEffect } from "react";
import { FontIcon, IconButton } from "@segfy/segfy-react-components";
import { TabsContainer } from "./style";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Tabs } from "./Tabs";

const ScrollableSelector = ({
  items,
  onSelectedItem,
  onAddItem,
  allowAddItem,
  onUpdateTitle,
  fullWidth,
  defaultSelectedId
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  function selectItem(index) {
    setSelectedIndex(index);
    onSelectedItem(items[index]?.payload);
  }

  useEffect(() => {
    if (items.length > 0 && selectedIndex == null && !defaultSelectedId) {
      setSelectedIndex(0);
      onSelectedItem(items[0]?.payload);
    }
  }, [items, onSelectedItem, selectedIndex, defaultSelectedId]);

  useEffect(() => {
    if (defaultSelectedId && items.length > 0) {

      const elementSearch = (element) => element.id > defaultSelectedId;

      setSelectedIndex(items.findIndex(elementSearch));
      onSelectedItem(items.find(elementSearch));
    }
  }, [defaultSelectedId, items, onSelectedItem]);

  

  return (
    <TabsContainer full_width={fullWidth}>
      {allowAddItem ? (
        <div>
          <IconButton
            onClick={() => onAddItem("New tab")}
            transparent_background
            primary
            icon="add"
          />
        </div>
      ) : (
        <></>
      )}

      <ScrollMenu
        data={Tabs(items, selectedIndex, onUpdateTitle)}
        selected={selectedIndex}
        onSelect={selectItem}
        arrowLeft={<FontIcon size={18} icon="arrow_left_thin" />}
        arrowRight={<FontIcon size={18} icon="arrow_right_thin" />}
      />
    </TabsContainer>
  );
};

ScrollableSelector.defaultProps = {
  items: [],
  onSelectedItem: () => {},
};

export default ScrollableSelector;
