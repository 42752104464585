import styled from "styled-components";
import { COLORS } from "@segfy/segfy-react-components";

const Container = styled.div`
  background-color: #faf7f2;
  padding: 1em;
  min-height: 80vh;

  & h2 {
    margin-bottom: 1em;
  }
`;

const TableContainer = styled.div`
  box-shadow: 5px 5px 5px 0px rgba(221, 221, 221, 1);
  padding: 1em;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    width: 100%;
    caption-side: top;
    border: none;
    border-collapse: collapse;
    caption-side: bottom;
  }

  table .visible-mobile {
    display: none;
  }

  table td,
  th {
    border: none;
    text-align: left;
  }

  table th {
    color: ${COLORS.color_neutral_dark};
    font-weight: lighter;
  }

  table td:last-of-type {
    display: flex;
    justify-content: flex-end;
  }

  table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-align: left;
  }

  table tr > td:nth-child(1) {
    width: 100px;
  }

  table tr > td:nth-child(2) {
    max-width: 140px;
    font-weight: bold;
  }

  table tr > td:nth-child(3) {
    max-width: 130px;
  }

  table tr > td:nth-child(3) {
    max-width: 110px;
  }

  table tr > td {
    padding: 1.1em 1.6em 1.1em 0.3em;
  }

  table tbody tr:not(.open-tokens) {
    height: 50px;
    :hover {
      background-color: ${COLORS.color_neutral_light};
    }
  }
  table thead > tr:not(.open-tokens) {
    background-color: ${COLORS.color_lightest};
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

export { Container, TableContainer, Centered };
