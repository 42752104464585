import styled from "styled-components";

const Container = styled.span`

display: flex;
justify-content: space-between;
align-items: center;

 & > *[contenteditable="true"] {
  border: 1.3px dashed #807973;
  border-radius: 5px;
  
}

& strong {
  margin: 0
}

*[contenteditable] {
padding: 2px 5px;
  
}
`;

export { Container };
