const createMapping = (lenght) => {
  let mapping = [];

  for (let i = 0; i < lenght; i++) {
    mapping.push({
      from: `data[${i}].id`,
      to: `questionnaires[${i}].id`,
    });

    mapping.push({
      from: `data[${i}].attributes.description`,
      to: `questionnaires[${i}].description`,
    });

    mapping.push({
      from: `data[${i}].attributes.kind`,
      to: `questionnaires[${i}].kind`,
    });

    mapping.push({
      from: `data[${i}].attributes.active`,
      to: `questionnaires[${i}].active`,
    });

    mapping.push({
      from: `data[${i}].attributes.version`,
      to: `questionnaires[${i}].version`,
    });

    mapping.push({
      from: `data[${i}].attributes.business`,
      to: `questionnaires[${i}].business`,
    });

    mapping.push({
      from: `data[${i}].attributes.trigger`,
      to: `questionnaires[${i}].trigger`,
    });

  }

  return mapping;
};

export default createMapping;
