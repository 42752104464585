const redirectToUrl = (url) => {
  window.location.href = url;
};

export function redirectToLogin() {
  const url =
    process.env.REACT_APP_SSO_FRONT || "REACT_APP_SSO_FRONT is missing";

  redirectToUrl(`${url}/login?callback=${window.location.href}`);
}

export function redirectToLogout() {
  const url =
    process.env.REACT_APP_SSO_FRONT || "REACT_APP_SSO_FRONT is missing";

  redirectToUrl(`${url}/logout?callback=${window.location.href}`);
}
