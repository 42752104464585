import React, { useState, useContext, useEffect, useCallback } from "react";
import QuestionnaireContext from "../../../contexts/questionnaire-context";
import { listFields } from "../../../services/questionnaire/field";
import { convertToViewModel } from "../../../converters/questionnaire/field/jsonapi-field-list-converter";
import SortableFieldsTree from "./SortableFieldsTree";
import { uniqueNamesGenerator, adjectives } from "unique-names-generator";
import { convertToJsonApi } from "../../../converters/questionnaire/field/new-field-jsonapi-converter";
import {
  createField,
  deleteField,
  updateField,
} from "../../../services/questionnaire/field";
import FieldOptionContext from "../../../contexts/field-option-context";

const Fields = () => {
  const { questionnaire } = useContext(QuestionnaireContext);
  const { fieldOption, setFieldOption } = useContext(FieldOptionContext);

  const [fields, setFields] = useState([]);
  const generateChildren = useCallback((arr, parent_id) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_id == parent_id /* eslint eqeqeq: 0 */) {
        var children = generateChildren(arr, arr[i].id);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }, []);

  const listAllFields = useCallback(
    (questionnaireId) => {
      listFields(questionnaireId, "?per_page=1000")
        .then((resp) => {
          console.log(resp.data);

          const vm = convertToViewModel(resp.data);

          const itemsWithChildren = generateChildren(vm?.fields);

          setFields(itemsWithChildren);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    [generateChildren]
  );

  useEffect(() => {
    if (fieldOption && questionnaire && !fieldOption.field?.isEditing) {
      listAllFields(questionnaire.id);
    }
  }, [fieldOption, questionnaire, listAllFields]);

  const addItem = () => {
    const newFieldName = uniqueNamesGenerator({
      dictionaries: [adjectives],
    });

    const jsonApi = convertToJsonApi({ title: newFieldName });

    createField(questionnaire.id, jsonApi)
      .then((resp) => {
        const id = resp.data.data.id;
        const title = resp.data.data.attributes.name;

        const currentFields = fields || [];
        setFields([...currentFields, ...[{ id, title }]]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const removeItem = (id, removeNode) => {
    deleteField(questionnaire.id, id)
      .then((resp) => {
        console.log(resp);
        removeNode();
        setFieldOption({ type: "reset" });
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const updateItemParent = (id, parent_id) => {
    const data = {
      data: {
        type: "fields",
        attributes: {
          parent_id: parent_id,
        },
      },
    };

    updateField(questionnaire.id, id, data)
      .then((resp) => {
        console.log(resp);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <>
      {questionnaire ? (
        <SortableFieldsTree
          addItem={addItem}
          removeItem={removeItem}
          setTreeData={setFields}
          updateItemParent={updateItemParent}
          treeData={fields}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Fields;
