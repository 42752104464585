import { get, post, put } from "../api-service";

const listCompanies = (params) => {
  return get(`/companies${params}`);
};

const createCompany = (data) => {
  return post(`/companies`, data);
};

const updateCompany = (id, data) => {
  return put(`/companies/${id}`, data);
};

export { listCompanies, createCompany, updateCompany };
