import React, { useState, useEffect, useReducer } from "react";
import QuestionnaireContext from "../../contexts/questionnaire-context";
import FieldOptionContext from "../../contexts/field-option-context";
import { Container } from "./style";
import QuestionnaireForm from "./QuestionnaireForm";
import QuestionnaireHeader from "./QuestionnaireHeader";
import Fields from "./Fields";
import CompaniesOptions from "./CompaniesOptions";
import FieldForm from "./Fields/Field/FieldForm";
import { showQuestionnaire } from "../../services/questionnaire";
import { convertToViewModel } from "../../converters/questionnaire/jsonapi-questionnaire-converter";
import OptionForm from "./Fields/Field/Options/Option/OptionForm";

const initialState = { type: null, field: null, option: null };

const reducer = (state, action) => {
  switch (action.type) {
    case "select-field":
      return {
        type: "field",
        option: null,
        field: { isEditing: false, payload: action.payload },
      };
    case "select-option":
      return {
        ...state,
        type: "option",
        option: { isEditing: false, payload: action.payload },
      };
    case "edit-field":
      return {
        ...state,
        type: "field",
        field: { ...state.field, isEditing: true },
        option: null,
      };
    case "edit-option":
      return {
        ...state,
        type: "option",
        option: { ...state.option, isEditing: true },
        field: { ...state.field, isEditing: false },
      };

    case "reset":
      return initialState;
    default:
      throw new Error();
  }
};

const Questionnaire = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [questionnaire, setQuestionnaire] = useState(null);

  const questionnaireContextValue = { questionnaire, setQuestionnaire };
  const fieldOptionContextValue = {
    fieldOption: state,
    setFieldOption: dispatch,
  };

  useEffect(() => {
    console.log(id);
    if (id) {
      showQuestionnaire(id)
        .then((resp) => {
          const newQuestionnaire = convertToViewModel(resp.data);
          setQuestionnaire(newQuestionnaire);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
        });
    }
  }, [id]);

  const isFieldOptionNotSelected = (fieldOption) => {
    return (
      fieldOption === null ||
      (fieldOption?.field === null && fieldOption?.option === null)
    );
  };

  const isFieldOptionSelectedAndNotEditing = (fieldOption) => {
    return (
      fieldOption !== null &&
      !fieldOption.field?.isEditing &&
      !fieldOption.option?.isEditing
    );
  };

  const isFieldSelectedAndEditing = (fieldOption) => {
    return fieldOption !== null && fieldOption.field?.isEditing;
  };

  const isOptionSelectedAndEditing = (fieldOption) => {
    return fieldOption !== null && fieldOption.option?.isEditing;
  };

  const QuestionnaireScreenContent = [
    {
      check: isFieldOptionNotSelected,
      component: <QuestionnaireForm />,
    },
    {
      check: isFieldOptionSelectedAndNotEditing,
      component: <CompaniesOptions />,
    },
    {
      check: isFieldSelectedAndEditing,
      component: <FieldForm />,
    },
    {
      check: isOptionSelectedAndEditing,
      component: <OptionForm />,
    },
  ];

  return (
    <QuestionnaireContext.Provider value={questionnaireContextValue}>
      <FieldOptionContext.Provider value={fieldOptionContextValue}>
        <Container>
          <Fields />
          <QuestionnaireHeader />
          {
            QuestionnaireScreenContent.find((q) =>
              q.check(fieldOptionContextValue.fieldOption)
            ).component
          }
        </Container>
      </FieldOptionContext.Provider>
    </QuestionnaireContext.Provider>
  );
};

export default Questionnaire;
