import { get, post, put, remove } from "../../api-service";

const deleteField = (questionnaire_id, id) => {
  return remove(`/questionnaires/${questionnaire_id}/fields/${id}`);
};

const listFields = (questionnaire_id, params) => {
  return get(`/questionnaires/${questionnaire_id}/fields${params}`);
};

const showField = (questionnaire_id, id) => {
  return get(`/questionnaires/${questionnaire_id}/fields/${id}`);
};

const createField = (questionnaire_id, data) => {
  return post(`/questionnaires/${questionnaire_id}/fields`, data);
};

const updateField = (questionnaire_id, id, data) => {
  return put(`/questionnaires/${questionnaire_id}/fields/${id}`, data);
};

export { deleteField, listFields, showField, createField, updateField };
