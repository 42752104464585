import styled, { css } from "styled-components";

const ErrorWrapperContainer = styled.div`
  ${(props) =>
    props.error &&
    css`
      & input {
        border-color: red;
      }
      & textarea {
        border-color: red;
      }
      .segfy-react-select__control {
        border: 1px solid red;
      }
    `};
`;

export { ErrorWrapperContainer };