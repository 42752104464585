const createMapping = (lenght) => {
  let mapping = [];

  for (let i = 0; i < lenght; i++) {
    mapping = [
      ...mapping,
      ...[
        {
          from: `data[${i}].id`,
          to: `fields[${i}].id`,
        },

        {
          from: `data[${i}].attributes.parent_id`,
          to: `fields[${i}].parent_id`,
        },

        {
          from: `data[${i}].attributes.name`,
          to: `fields[${i}].title`,
        },

        {
          from: `data[${i}].attributes.expanded`,
          to: `fields[${i}].expanded`,
          fallback_value: true
        },

        
      ],
    ];
  }

  return mapping;
};

export default createMapping;
