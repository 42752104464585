import styled from "styled-components";

const Container = styled.div`
 & > *[contenteditable="true"] {
  border: 1.3px dashed #807973;
  border-radius: 5px;
  
}

*[contenteditable] {
padding: 2px 5px;
  
}
`;

export { Container };
