import { map } from "guinho-automapper-js/index";
import mapping  from "../../../../mappers/questionnaire/field/option/edit/jsonapi-option-map";

const convertToViewModel = (data) => {

  const viewModel = map(data, {}, mapping);

  return viewModel;
};

export { convertToViewModel };