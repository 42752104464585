import { map } from "guinho-automapper-js/index";
import mapping  from "../../mappers/questionnaire/edit/jsonapi-questionnaire-map";

const convertToViewModel = (data) => {

  const viewModel = map(data, {}, mapping);

  return viewModel;
};

export { convertToViewModel };