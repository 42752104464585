import { methods } from "@segfy/segfy-react-components";

export function showLoadingSpinner() {
  if (!(window).__POWERED_BY_QIANKUN__) {
    methods.show();
  } else {
    //showRemoteSpinner();
  }
}

export function hideLoadingSpinner() {
  if (!(window).__POWERED_BY_QIANKUN__) {
    methods.hide();
  } else {
    //hideRemoteSpinner();
  }
}
