import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CredentialContext from '../../contexts/credential-context';
import Brokers from '../Brokers';
import CredentialForm from '../Brokers/CredentialForm';
import Questionnaire from '../Questionnaire';
import { Verification } from '../Verification';

function AutomationTabs({ match }) {
  const [isLogged, setIsLogged] = useState(false);

  const { id } = match.params;

  const [credential, setCredential] = useState(null);

  const credentialContextValue = { credential, setCredential };

  const [tabIndex, setTabIndex] = useState(0);

  if (!isLogged) {
    return <Verification isLogged={isLogged} setIsLogged={setIsLogged} />;
  }

  return (
    <>
      <CredentialContext.Provider value={credentialContextValue}>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Questionários</Tab>
            <Tab>Corretoras</Tab>
          </TabList>

          <TabPanel>
            <Questionnaire id={id} />
          </TabPanel>
          <TabPanel>
            {credential?.isEditing || credential?.isCreating ? (
              <CredentialForm />
            ) : (
              <Brokers />
            )}
          </TabPanel>
        </Tabs>
      </CredentialContext.Provider>
    </>
  );
}

export default AutomationTabs;
