import { get, post, put, remove } from "../../../api-service";

const deleteOption = (questionnaire_id, field_id, id) => {
  return remove(
    `/questionnaires/${questionnaire_id}/fields/${field_id}/options/${id}`
  );
};

const listOptions = (questionnaire_id, field_id) => {
  return get(
    `/questionnaires/${questionnaire_id}/fields/${field_id}/options?per_page=100`
  );
};

const showOption = (questionnaire_id, field_id, id) => {
  return get(
    `/questionnaires/${questionnaire_id}/fields/${field_id}/options/${id}`
  );
};

const createOption = (questionnaire_id, field_id, data) => {
  return post(
    `/questionnaires/${questionnaire_id}/fields/${field_id}/options`,
    data
  );
};

const updateOption = (questionnaire_id, field_id, id, data) => {
  return put(
    `/questionnaires/${questionnaire_id}/fields/${field_id}/options/${id}`,
    data
  );
};

export { deleteOption, listOptions, showOption, createOption, updateOption };
