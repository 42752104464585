import React, { useState, useEffect, useContext, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import QuestionnaireContext from "../../../contexts/questionnaire-context";
import { Container, CenteredButton, ErrosContainer } from "./style";
import Select from "react-select";
import {
  Panel,
  MarginTop,
  ItemSpan1,
  ItemSpan2,
  SegfyInputWrapper,
  segfyTheme,
  customStylesDefault,
  Button,
  Label,
} from "@segfy/segfy-react-components";
import { useHistory } from "react-router-dom";
import { validationSchema } from "./validator";
import ErrorWrapper from "../../ErrorWrapper";
import { convertToJsonApi } from "../../../converters/questionnaire/questionnaire-jsonapi-converter";
import {
  updateQuestionnaire,
  createQuestionnaire,
} from "../../../services/questionnaire";

import { convertToViewModel } from "../../../converters/questionnaire/jsonapi-questionnaire-converter";
import serverErrorHandle from "../../../handlers/server-error-handler";

const kindsOptions = [
  { value: "car", label: "Carro" },
  { value: "motorcycle", label: "Moto" },
  { value: "truck", label: "Caminhão" },
  { value: "residential", label: "Residencial" },
  { value: "life", label: "Vida" },
  { value: "credential", label: "Credencial" },
  { value: "enterprise", label: "Empresarial" },
  { value: "premium", label: "Premium" },
];

const businessOptions = [
  { value: "vehicle", label: "Veicular" },
  { value: "residence", label: "Residencial" },
  { value: "document", label: "Documentos" },
  { value: "life", label: "Vida" },
  { value: "enterprise", label: "Empresarial" },
];

const QuestionnaireForm = () => {
  const { questionnaire, setQuestionnaire } = useContext(QuestionnaireContext);

  let history = useHistory();

  const [questionnaireKind, setQuestionnaireKind] = useState(null);
  const [questionnaireBusiness, setQuestionnaireBusiness] = useState(null);
  const [isAddMode, setIsAddMode] = useState(true);

  const {
    register,
    reset,
    control,
    errors,
    handleSubmit,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
  });

  function onSubmit(data) {
    const jsonApi = convertToJsonApi(data);
    if (isAddMode) {
      createQuestionnaire(jsonApi)
        .then((resp) => {
          history.push(`/${resp.data.data.id}`);
          convertToViewModelAndSetQuestionnaire(resp);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
          serverErrorHandle(error.response.data.errors, setError);
        });
    } else {
      updateQuestionnaire(questionnaire.id, jsonApi)
        .then((resp) => {
          
          convertToViewModelAndSetQuestionnaire(resp);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
          serverErrorHandle(error.response.data.errors, setError);
        });
    }
  }

  const convertToViewModelAndSetQuestionnaire = (resp) => {
    const newQuestionnaire = convertToViewModel(resp.data);
    setQuestionnaire(newQuestionnaire);
  };

  useEffect(() => {
    if (questionnaire) {
      const kind = kindsOptions.find((k) => k.value === questionnaire.kind);
      setQuestionnaireKind(kind);

      const business = businessOptions.find(
        (k) => k.value === questionnaire.business
      );

      console.log(business);
      
      setQuestionnaireBusiness(business);

      reset(questionnaire);

      setIsAddMode(false);
    }
  }, [questionnaire, reset]);

  const resetForm = useCallback(() => {
    setQuestionnaireKind(null);
    setQuestionnaireBusiness(null);
    setIsAddMode(true);

    setValue("kind", "");
    setValue("business", "");
    reset({});
  }, [setValue, reset]);

  useEffect(() => {
    if (!questionnaire && !isAddMode) {
      resetForm();
    }
  }, [questionnaire, isAddMode, resetForm]);

  return (
    <div className="questionnaire-form">
      <MarginTop>
        <Panel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ErrosContainer>
              <ErrorWrapper name={`general-errors`} errors={errors} />
            </ErrosContainer>

            <Container>
              <ItemSpan2>
                <ErrorWrapper name={`active`} errors={errors}>
                  <Label>
                    <input
                      type="checkbox"
                      name="active"
                      defaultValue={true}
                      defaultChecked={true}
                      ref={register}
                    />
                    Ativo
                  </Label>
                </ErrorWrapper>
              </ItemSpan2>

              <ItemSpan1>
                <ErrorWrapper name={`description`} errors={errors}>
                  <SegfyInputWrapper label={"Descrição*"}>
                    <input
                      type="text"
                      name={`description`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`kind`} errors={errors}>
                  <SegfyInputWrapper label={"Tipo*"}>
                    <Controller
                      name={`kind`}
                      control={control}
                      defaultValue={""}
                      render={(props) => (
                        <Select
                          classNamePrefix="segfy-react-select"
                          theme={segfyTheme}
                          styles={customStylesDefault}
                          value={questionnaireKind}
                          options={kindsOptions}
                          onChange={(e) => {
                            setQuestionnaireKind(e);
                            props.onChange(e.value);
                          }}
                        />
                      )}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`version`} errors={errors}>
                  <SegfyInputWrapper label={"Versão"}>
                    <input
                      type="text"
                      name={`version`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1></ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`trigger`} errors={errors}>
                  <SegfyInputWrapper label={"Gatilho"}>
                    <input
                      type="text"
                      name={`trigger`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`business`} errors={errors}>
                  <SegfyInputWrapper label={"Ramo*"}>
                    <Controller
                      name={`business`}
                      control={control}
                      defaultValue={""}
                      render={(props) => (
                        <Select
                          classNamePrefix="segfy-react-select"
                          theme={segfyTheme}
                          styles={customStylesDefault}
                          value={questionnaireBusiness}
                          options={businessOptions}
                          onChange={(e) => {
                            setQuestionnaireBusiness(e);
                            props.onChange(e.value);
                          }}
                        />
                      )}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>
            </Container>
            <MarginTop>
              <ItemSpan2 align_items="center">
                <CenteredButton>
                  <Button type="submit" primary small>
                    Salvar
                  </Button>
                </CenteredButton>
              </ItemSpan2>
            </MarginTop>
          </form>
        </Panel>
      </MarginTop>
    </div>
  );
};

export default QuestionnaireForm;
