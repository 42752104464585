import styled from "styled-components";

const Container = styled.div`
  display: grid;

  grid-template-columns: 1fr 1.1fr 1.1fr;
  grid-template-rows: repeat(1fr, 4);
  grid-template-areas:
    "header header header"
    "fields form form"
    "fields form form"
    "fields form form";

    & .questionnaire-header{
        grid-area: header;
    }

    & .questionnaire-form{
        grid-area: form;
    }

    & .field-form{
        grid-area: form;
    }

    & .option-form{
        grid-area: form;
    }

    & .questionnaire-fields{
        grid-area: fields;
    }
`;

export { Container };
