const mapping = [
    {
      from: "type",
      to: "data.type",
      fallback_value: "options",
    },
    {
      from: "description",
      to: "data.attributes.description",
    },
    {
      from: "value",
      to: "data.attributes.value",
    },
  ];
  
  export default mapping;
