const mapping = [
  {
    from: "type",
    to: "data.type",
    fallback_value: "company_options",
  },
  {
    from: "company_id",
    to: "data.attributes.company_id",
  },
  {
    from: "option_id",
    to: "data.attributes.option_id",
    fallback_value: null,
  },
  {
    from: "field_id",
    to: "data.attributes.field_id",
    fallback_value: null,
  },
  {
    from: "value",
    to: "data.attributes.value",
  },
  {
    from: "description",
    to: "data.attributes.description",
  },
  {
    from: "code",
    to: "data.attributes.code",
  },
];

export default mapping;
