import { map } from "guinho-automapper-js/index";
import createMapping  from "../../mappers/company/list/jsonapi-company-list-map";

const convertToViewModel = (data) => {

  const viewModel = map(data, {}, createMapping(data.data.length));

  return viewModel;
};

export { convertToViewModel };