import React, { useEffect } from "react";
import { redirectToLogout } from "../../helpers/redirectToLogin";
import { ReactComponent as Logo } from "../../assets/Segfy.svg";
import { Button } from "@segfy/segfy-react-components";
import { ForbiddenContainer } from "./styles";

export const Forbidden = () => {
  useEffect(() => {
    setTimeout(() => redirectToLogout(), 5000);
  }, []);
  return (
    <ForbiddenContainer>
      <Logo />
      <h3>Usuário não permitido, por favor tente outra conta.</h3>
      <Button primary onClick={() => redirectToLogout()}>
        Sair
      </Button>
    </ForbiddenContainer>
  );
};
