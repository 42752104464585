import styled from "styled-components";

const Container = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 5fr;

  grid-column: span 2;
`;

export { Container };