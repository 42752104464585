import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, CenteredButton, ErrosContainer } from "./style";
import {
  Panel,
  MarginTop,
  ItemSpan1,
  ItemSpan2,
  SegfyInputWrapper,
  Button,
  Heading2,
  MarginLeft,
} from "@segfy/segfy-react-components";
import { validationSchema } from "./validator";
import ErrorWrapper from "../../../ErrorWrapper";
import serverErrorHandle from "../../../../handlers/server-error-handler";
import FieldOptionContext from "../../../../contexts/field-option-context";
import { convertToJsonApi } from "../../../../converters/companyOptions/company-options-jsonapi-converter";
import {
  filterCompaniesOptions,
  createCompanyOption,
  updateCompanyOption,
} from "../../../../services/companyOptions";
import { convertToViewModel } from "../../../../converters/companyOptions/jsonapi-company-options-converter";

const fieldOptionsTypesIdFields = { option: "option_id", field: "field_id" };

const CompanyOptionForm = ({ company }) => {
  const { fieldOption } = useContext(FieldOptionContext);

  const formEnabled = company != null;

  const [companyOptionId, setCompanyOptionId] = useState(null);
  const isAddMode = companyOptionId == null;

  const {
    register,
    reset,
    errors,
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
  });

  function onSubmit(data) {
    const idField = fieldOptionsTypesIdFields[fieldOption.type];
    const fieldOptionId = fieldOption[fieldOption.type].payload.id;

    const companyOptionVM = {
      ...data,
      company_id: company.id,
      [idField]: fieldOptionId,
    };

    const jsonApi = convertToJsonApi(companyOptionVM);

    if (isAddMode) {
      createCompanyOption(jsonApi)
        .then((resp) => {
          setCompanyOptionId(resp.data.data.id);
          console.log(resp.data.data.id);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
          serverErrorHandle(error.response.data.errors, setError);
        });
    } else {
      updateCompanyOption(companyOptionId, jsonApi)
        .then((resp) => {
          console.log(resp);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
          serverErrorHandle(error.response.data.errors, setError);
        });
    }
  }

  useEffect(() => {
    if (company && fieldOption) {
      reset({});

      const idField = fieldOptionsTypesIdFields[fieldOption.type];
      const fieldOptionId = fieldOption[fieldOption.type].payload.id;

      const filter = `company_id=${company.id}&${idField}=${fieldOptionId}`;

      filterCompaniesOptions(filter)
        .then((resp) => {
          if (!resp.data.data) return;

          const result = resp.data.data[0];

          const viewModel = convertToViewModel(result);

          setCompanyOptionId(viewModel.id);

          reset(viewModel);
        })
        .catch(function (error) {
          console.log(error.response.data.errors);
        });
    }
  }, [company, fieldOption, reset]);

  return (
    <div className="company-options-form">
      <MarginLeft margin="1.5em">
      <Heading2>{fieldOption[fieldOption.type].payload.title}</Heading2>
      </MarginLeft>

      <MarginTop>
        <Panel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ErrosContainer>
              <ErrorWrapper name={`general-errors`} errors={errors} />
            </ErrosContainer>

            <Container>
              <ItemSpan1>
                <ErrorWrapper name={`description`} errors={errors}>
                  <SegfyInputWrapper label={"Descrição*"}>
                    <input
                      disabled={!formEnabled}
                      type="text"
                      name={`description`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan1>
                <ErrorWrapper name={`value`} errors={errors}>
                  <SegfyInputWrapper label={"Valor (obrigatório se descrição vazia)"}>
                    <input
                      disabled={!formEnabled}
                      type="text"
                      name={`value`}
                      ref={register}
                      defaultValue={""}
                    />
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan1>

              <ItemSpan2>
                <ErrorWrapper name={`code`} errors={errors}>
                  <SegfyInputWrapper label={"Código*"}>
                    <textarea
                      disabled={!formEnabled}
                      type="text"
                      name={`code`}
                      ref={register}
                      defaultValue={""}
                    ></textarea>
                  </SegfyInputWrapper>
                </ErrorWrapper>
              </ItemSpan2>
            </Container>
            <MarginTop>
              <ItemSpan2 align_items="center">
                <CenteredButton>
                  <Button disabled={!formEnabled} type="submit" primary small>
                    Salvar
                  </Button>
                </CenteredButton>
              </ItemSpan2>
            </MarginTop>
          </form>
        </Panel>
      </MarginTop>
    </div>
  );
};

export default CompanyOptionForm;
