import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorText } from "@segfy/segfy-react-components";
import { ErrorWrapperContainer } from "./styles";
import { get } from "react-hook-form";

const ErrorWrapper = (props) => {
  const error = get(props.errors, props.name);

  return (
    <ErrorWrapperContainer error={error}>
      {props.children}
      <ErrorMessage
        as={<ErrorText />}
        name={props.name}
        errors={props.errors}
      />
    </ErrorWrapperContainer>
  );
};

export default ErrorWrapper;
