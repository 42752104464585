const mapping = [
  {
    from: "data.id",
    to: "id",
  },
  {
    from: "data.attributes.description",
    to: "description",
  },
  {
    from: "data.attributes.kind",
    to: "kind",
  },
  {
    from: "data.attributes.active",
    to: "active",
  },
  {
    from: "data.attributes.version",
    to: "version",
  },
  {
    from: "data.attributes.trigger",
    to: "trigger",
  },

  {
    from: "data.attributes.business",
    to: "business",
  },

  
];

export default mapping;
