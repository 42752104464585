const mapping = [
  {
    from: "type",
    to: "data.type",
    fallback_value: "fields",
  },
  {
    from: "description",
    to: "data.attributes.description",
  },
  {
    from: "name",
    to: "data.attributes.name",
  },
  {
    from: "params",
    to: "data.attributes.params",
    fallback_value: null,
  },
  {
    from: "validations",
    to: "data.attributes.validations",
    fallback_value: null,
  },
];

export default mapping;
