import styled from "styled-components";
import { COLORS } from "@segfy/segfy-react-components";

const TableContainer = styled.div`
  border: 1px solid ${COLORS.color_neutral_medium};
  border-radius: 5px;
  background-color: ${COLORS.color_neutral_light};
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    width: 100%;
    caption-side: top;
    border: none;
    border-collapse: collapse;
    caption-side: bottom;
  }

  table .visible-mobile {
    display: none;
  }

  table td,
  th {
    border: none;
    text-align: left;
  }

  table th {
    color: ${COLORS.color_neutral_dark};
    font-weight: lighter;
  }

  table td:last-of-type {
    display: flex;
    justify-content: flex-end;
  }

  table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-align: left;
  }

  table tr > td:nth-child(1) {
    width: 100px;
  }

  table tr > td:nth-child(2) {
    max-width: 140px;
    font-weight: bold;
  }

  table tr > td:nth-child(3) {
    max-width: 130px;
  }

  table tr > td:nth-child(3) {
    max-width: 110px;
  }

  table tr > td {
    padding: 1.1em 1.6em 1.1em 0.3em;
  }

  table tbody tr {
    height: 50px;
    :hover {
      background-color: ${COLORS.color_neutral_medium};
    }
  }
  table thead > tr {
    background-color: ${COLORS.color_lightest};
  }
`;

export { TableContainer };
